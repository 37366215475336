import * as React from "react";
import logo from '../img/eatmdLogo.png';

function SvgComponent() {
  return (
    <img src={logo} width={112} height={45} />
  );
}

export default SvgComponent;
