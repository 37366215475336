import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">طلب الخدمات والأسعار
</h1>
            <p className="font13">
              لاتحاتي،،  
              <br />
            أسعارنا بمتناول اليد
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price=" 30 SAR"
                title="بدائية"
                text="باقة مبسطة"
                offers={[
                  { name: "حذف البيانات", cheked: true },
                  { name: "فحص الجهاز والقطع", cheked: true },
                  { name: "اصدار شهادة", cheked: false },
                  { name: "تغليف واعتماد مرتبط", cheked: false },
                ]}
                action={() => alert("تم طلب الباقة المبسطة")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price=" 50 SAR"
                title="شاملة"
                text="باقة شاملة"
                offers={[
                  { name: "حذف البيانات", cheked: true },
                  { name: "فحص الجهاز والقطع", cheked: true },
                  { name: "اصدار شهادة", cheked: true },
                  { name: "تغليف واعتماد مرتبط", cheked: false },
                ]}
                action={() => alert("تم طلب الباقة الشاملة")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price=" 80 SAR"
                title="ذهبية"
                text="باقة ذهبية"
                offers={[
                  { name: "حذف البيانات", cheked: true },
                  { name: "فحص الجهاز والقطع", cheked: true },
                  { name: "اصدار شهادة", cheked: true },
                  { name: "تغليف واعتماد مرتبط", cheked: true },
                ]}
                action={() => alert("تم طلب الباقة الذهبية")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




