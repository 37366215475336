import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import Link from "react-scroll/modules/components/Link";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <RightSide className="flexCenter">
        {/* <div>
        <HeaderPP className="font30 semiBold"> 🚀 خيارك الذكي في فحص واعتماد الأجهزة الذكية المستعملة
        </HeaderPP>

        <HeaderP className="font17 semiBold">
            <li>🩺 تقييم حالة الجهاز شامل الشبكة وحالة البطارية والقطع وإصدار شهادة معتمدة</li>
            <li>📜 نسهل عملية البيع والشراء  </li>
            <li>🚮 نقوم بحذف جميع بياناتك وحساباتك ونحميها من الإسترجاع</li>
            <li>📦 تغليف احترافي وآمن</li>
                      <br></br>
          <BtnWrapper>
            <Link className="radius8 lightBg" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
           الخدمات والأسعار
            </Link>  
          </BtnWrapper>
          </HeaderP>
        </div> */}
        <div>
        <HeaderPP className="font30 semiBold">  ماهو اعتمد؟🚀
        </HeaderPP>

        <HeaderP className="font17 semiBold">
          اعتمد هي منصة الكترونية تسهل وتنوب عنك في فحص الأجهزة الذكية قبل شرائها او بيعها بواسطة فريق تقني مختص مما يساعد المشتري على إتخاذ القرار بناءً على تقرير ومعلومات موثوقة 
                      <br></br>
                      <br></br>
          <BtnWrapper>
            <Link className="radius8 lightBg" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
           الخدمات والأسعار
            </Link>  
          </BtnWrapper>
          <br></br>
          <br></br>
          <HeaderP className="font13 semiBold">
          ليش تعتمد؟
          بإختصار بنريح راسك من حوسة مابعد البيع لأننا راح:
            <li>🩺 نقيم حالة الجهاز شامل القطع و الشبكة وحالة البطارية وبنصدرلك شهادة معتمدة</li>
            <li>💰 نسهل قرار عملية البيع والشراء  </li>
            <li>🚮 نقوم بحذف جميع بياناتك وحساباتك ونحميها من الإسترجاع</li>
            <li>📦 تغليف احترافي وآمن</li>
          </HeaderP>
          </HeaderP>
        </div>

      </RightSide>
      <LeftSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em> عملية البيع للجهاز كانت اسرع ب200٪ بعد شراء الخدمة الذهبية! اعتمد واطلبها</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>من آراء عملائنا</p>
            </div>
          </QuoteWrapper>
          {/* <DotsWrapper>
            <Dots />
          </DotsWrapper> */}
        </ImageWrapper>
        {/* <GreyDiv className="lightBg"></GreyDiv> */}
      </LeftSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 30px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const HeaderPP = styled.div`
  max-width: 470px;
  padding: 15px 0 30px 0;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


